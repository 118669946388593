import React from "react";

/*** COMPONENTS ***/
import Main from "../components/main";
import SEO from "../components/seo";
import "../styles/contact.css";
import sayHiHeader from "../images/say-hi.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";

/*  <h1 class="grid-item-header-text-sayhi">JOHANNA.GRANSTROM@GMAIL.COM</h1> 
<img alt="email address" src={emailAddress} /> */

function openLinkedIn() {
  window.open("https://www.linkedin.com/in/johannagranstrom/", "_blank");
}
const contact = () => (
  <Main>
    <SEO title="Say Hi" />
    <div class="container-sayhi">
      <div class="grid-item-11-sayhi ">
        <img alt="header say hi" alt="" src={sayHiHeader} />
        <div class="circle-sayhi ">
          <p class="text-black-sayhi">SAY HI</p>
          <p class="text-grey-sayhi">SEND ME AN EMAIL</p>
        </div>
      </div>
      <div class="grid-item-email-sayhi">
        <h1 class="grid-item-email-text-sayhi">JOHANNA.GRANSTROM@GMAIL.COM</h1>
      </div>
      <div class="grid-item-21-sayhi ">
        <p>
          I'm intrigued by problem solving and design. I work at BookBeat as a
          junior frontend web developer.{" "}
          <button
            class="project-text-link-style"
            onClick={() => openLinkedIn()}
          >
            Say hi!
          </button>
          <br />
          <br />
          Johanna Granström
          <br />
          <FontAwesomeIcon icon={faMapMarker} /> {"  "}Stockholm, Sweden
        </p>
      </div>
    </div>
  </Main>
);

export default contact;
